import React, { useState } from "react";
import { HiMenu, HiX } from "react-icons/hi";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  
  const handelClose = ()=>setNav(!nav)

  return (
    <div className="w-full h-[80px] z-10 bg-zinc-200 drop-shadow-lg fixed">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <h1 className="text-3xl font-bold mr-4 sm:text-4xl">BRAND</h1>
          <ul className="hidden md:flex cursor-pointer">
            <li>
              <Link to="home" smooth={true} offset={50} duration={600}>
                Home
              </Link>
            </li>
            <li>
              <Link to="about" smooth={true} offset={50} duration={500}>
                About
              </Link>
            </li>
            <li>
              <Link to="support" smooth={true} offset={50} duration={500}>
                Support
              </Link>
            </li>
            <li>
              <Link to="plateforms" smooth={true} offset={50} duration={500}>
                Plateforms
              </Link>
            </li>
            <li>
              <Link to="pricing" smooth={true} offset={50} duration={500}>
                Pricing
              </Link>
            </li>
            {/* <li>About</li>
            <li>Support</li>
            <li>Platforms</li>
            <li>Pricing</li> */}
          </ul>
        </div>
        <div className="hidden md:flex pr-4">
          <button className="border-none bg-transparent text-black mr-4">
            Sign in
          </button>
          <button className="px-8 py-3">Sign up</button>
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? (
            <HiMenu className="h-9 w-9 cursor-pointer" />
          ) : (
            <HiX className="h-9 w-9" />
          )}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
      <li className="border-b-2 border-zinc-300 w-full">
              <Link onClick={handelClose} to="home" smooth={true} offset={50} duration={600}>
                Home
              </Link>
            </li>
            <li className="border-b-2 border-zinc-300 w-full">
              <Link onClick={handelClose} to="about" smooth={true} offset={50} duration={500}>
                About
              </Link>
            </li>
            <li className="border-b-2 border-zinc-300 w-full">
              <Link onClick={handelClose} to="support" smooth={true} offset={50} duration={500}>
                Support
              </Link>
            </li>
            <li className="border-b-2 border-zinc-300 w-full">
              <Link onClick={handelClose} to="plateforms" smooth={true} offset={50} duration={500}>
                Plateforms
              </Link>
            </li>
            <li className="border-b-2 border-zinc-300 w-full">
              <Link onClick={handelClose} to="pricing" smooth={true} offset={50} duration={500}>
                Pricing
              </Link>
            </li>
        {/* <li className="border-b-2 border-zinc-300 w-full">Home</li>
        <li className="border-b-2 border-zinc-300 w-full">About</li>
        <li className="border-b-2 border-zinc-300 w-full">Support</li>
        <li className="border-b-2 border-zinc-300 w-full">Platforms</li>
        <li className="border-b-2 border-zinc-300 w-full">Pricing</li> */}
        <div className="flex flex-col my-4">
          <button className="bg-transparent text-indigo-600 px-8 py-3 mb-4">
            Sign in
          </button>
          <button className="px-8 py-3">Sign up</button>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
