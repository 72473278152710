import React from "react";
import { FiCheck } from "react-icons/fi";

const AllInOne = () => {
  return (
    <div name='plateforms' className="w-full my-32">
      <div className="max-w-[1240px] mx-auto px-2">
        <h2 className="text-5xl font-bold text-center">All-In-One Platform</h2>
        <p className="text-2xl py-8 text-gray-500 text-center">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus,
          incidunt.
        </p>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 pt-4">

          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FiCheck className="h-7 w-7 mr-4 text-green-600" />
            </div>
            <div className="">
              <h3 className="font-bold text-lg">Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
                ea.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInOne;
